<ng-container *ngIf="authenticated() && allMenuItems().length">
  <div
    *ngIf="hasExpiredLicenseBannerVisible()"
    class="flex justify-content-center align-items-center gap-2 bg-red-600 text-white font-semibold no-license-container p-2">
    <span class="pi pi-info-circle"></span>&nbsp;
    <span>{{ 'expiredLicenseTopBar.expiredLicenseText' | translate }}</span
    >&nbsp;<a [routerLink]="'products'" class="text-white font-bold"
      ><span> {{ 'expiredLicenseTopBar.renewText' | translate }}</span></a
    >
  </div>
  <p-menubar [model]="allMenuItems()" styleClass="px-2">
    <ng-template pTemplate="end">
      <div class="flex flex-row align-items-center gap-4">
        <fizjo-pro-messages-notification></fizjo-pro-messages-notification>
        @if (hasOrder()) {
          <p-button
            icon="pi pi-shopping-cart"
            severity="info"
            [rounded]="true"
            [outlined]="true"
            (click)="orderHandler()"></p-button>
        }
        <ng-container *ngIf="initials()">
          <fizjo-pro-switch-app-user></fizjo-pro-switch-app-user>
        </ng-container>
      </div>
    </ng-template>
  </p-menubar>
</ng-container>
<div class="app-layout">
  <p-scrollPanel [style]="containerStyle()">
    <ng-content></ng-content>
  </p-scrollPanel>
</div>
